import React from 'react'
import '../Components/components-css/mobile-menu.css';

import { useState, useEffect, useRef } from 'react';

import { HashLink } from 'react-router-hash-link';

export const MobileMenu = () => {

    const data = [{
        text: 'Gastronomía',
        link: '/gastronomia'
    },
    {
        text: 'entretenimiento',
        link: '/entretenimiento'
    },
    {
        text: 'quienes somos',
        link: '/quienes-somos'
    },
    {
        text: 'eventos',
        link: '/eventos'
    }]

    const MobileMenuPopUp = () => {


        return (
        <div className='mobile-menu-bg-wrapper'>
            <div className='mobile-menu-container'>
                <div className='mobile-menu-content-container'>
                    <div className='mobile-menu-first-row'>
                        MENU
                    </div>
                    {
                        Object.keys(data).map((item, index) => (
                            <LinkRow text={data[item].text} link={data[item].link} key={index}/>
                        ))
                    }
                </div>
                <div className='close-menu-wrapper'>
                    <div className='close-menu-button' onClick={() => {setOpenMenu(false)}}>
                        <svg width="36" height="37" viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M34.5728 35.0725L1.42773 1.92749" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M1.42773 35.0725L34.5728 1.92749" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </div>
                </div>
            </div>
        </div>
        )
    }

    const LinkRow = ({text, link}) => (
        
            <HashLink to={link + "#"} style={{textDecoration: 'none'}}>
                <div className='mobile-menu-link-row'>
                    <div>{text}</div>
                    <div className='link-row-arrow'>
                        <svg width="27" height="29" viewBox="0 0 27 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.7308 0.999999L25.7734 14.0426C25.8985 14.1678 25.8985 14.3707 25.7734 14.4959L12.7308 27.5385" stroke="black" strokeWidth="1.28205" strokeLinecap="round"/>
                        <path d="M25.2305 14.718L0.999699 14.718" stroke="black" strokeWidth="1.28205" strokeLinecap="round"/>
                        </svg>
                    </div>
                </div>
            </HashLink>
        
    )

    const [openMenu, setOpenMenu] = useState(false);

  return (
    <>

    {openMenu && <MobileMenuPopUp />}
    <div className='mobile-menu-button-wrapper'>
    <div className='mobile-menu-button' onClick={() => {setOpenMenu(!openMenu)}}>
        <div className='mobile-menu-text'>
            Ronda® Home
        </div>
            <svg width="20" height="12" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 1H19H1Z" fill="black"/>
            <path d="M1 1H19" stroke="black" strokeWidth="2" strokeLinecap="round"/>
            <path d="M1 6H19H1Z" fill="black"/>
            <path d="M1 6H19" stroke="black" strokeWidth="2" strokeLinecap="round"/>
            <path d="M1 11H19H1Z" fill="black"/>
            <path d="M1 11H19" stroke="black" strokeWidth="2" strokeLinecap="round"/>
            </svg>
    </div>
    </div>
    </>
  )
}
