import React from "react";
import './components-css/footer.css';
import FooterItem from "./FooterItem";
import { HashLink } from "react-router-hash-link";

import { useState, useEffect } from "react";

import {ReactComponent as InstaLogo} from '../assets/imgs/socials/insta.svg';
import {ReactComponent as TiktokLogo} from '../assets/imgs/socials/tiktok.svg';
import {ReactComponent as FbLogo} from '../assets/imgs/socials/fb.svg';
import {ReactComponent as EmailLogo} from '../assets/imgs/socials/email.svg';

import Dot from '../assets/imgs/footer/dot.webp';



function Footer({style, entSite}) {
    
    const [hover, setHover] = useState(false);

    
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    
    useEffect(() => {
        const handleResize = () => {
        setWindowWidth(window.innerWidth);
        };
    
        // Add event listener
        window.addEventListener('resize', handleResize);
    
        // Clean up event listener on unmount
        return () => {
        window.removeEventListener('resize', handleResize);
        };
    }, []);

    if (windowWidth > 478) {

    


    return (
        <>
        <div className={`footer-wrapper ${entSite ? 'footer-margin-entertainment' : ''}`} style={style}>
        <div className="footer-boxes-wrapper">
                <div className="column-1-wrapper">
                    <HashLink to={'/home#'}>
                        <div className="item-baseline" style={{paddingBottom: 'min(2.22vw, 32px)'}}>
                        <div className="item-wrapper">
                        <div style={{width: 'min(12.6vw, 182px)', height: 'min(2.5vw, 36px)'}}>
                            <svg width="100%" height="100%" viewBox="0 0 182 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M51.1887 0.861328C62.124 0.861328 70.6504 8.72804 70.6504 18.8613C70.6504 28.9946 62.124 36.8613 51.1887 36.8613C40.2534 36.8613 31.727 28.9946 31.727 18.8613C31.727 8.72804 40.2534 0.861328 51.1887 0.861328ZM50.841 24.4974C54.1026 24.7019 56.7764 22.0235 56.575 18.7563C56.4094 16.0723 54.2157 13.8776 51.5364 13.709C48.2748 13.5072 45.601 16.1829 45.8052 19.4501C45.9708 22.1341 48.1645 24.3288 50.8438 24.4974" fill="white"/>
                            <path d="M73.7878 1.39204H84.2843C84.8362 1.39204 85.3522 1.66293 85.664 2.11901L95.4266 16.3045L95.5232 16.2575V3.07263C95.5232 2.14388 96.2737 1.39204 97.2009 1.39204H106.759C107.686 1.39204 108.437 2.14388 108.437 3.07263V34.6528C108.437 35.5815 107.686 36.3334 106.759 36.3334H96.2599C95.7081 36.3334 95.1921 36.0625 94.8802 35.6064L85.1177 21.4209L85.0211 21.4679V34.6528C85.0211 35.5815 84.2705 36.3334 83.3434 36.3334H73.785C72.8579 36.3334 72.1073 35.5815 72.1073 34.6528V3.07263C72.1073 2.14388 72.8579 1.39204 73.785 1.39204" fill="white"/>
                            <path d="M33.5813 33.8871L26.8843 20.7935C29.4864 18.9608 31.269 16.2575 31.269 12.4928C31.2662 3.17767 21.4871 1.39204 14.3072 1.39204H1.67768C0.750543 1.39204 0 2.14388 0 3.07263V34.6528C0 35.5815 0.750543 36.3334 1.67768 36.3334H12.6792C13.6064 36.3334 14.3569 35.5815 14.3569 34.6528V24.8457H14.5501L19.3072 35.3466C19.5803 35.9464 20.1764 36.3334 20.8331 36.3334H32.0885C33.3412 36.3334 34.1525 35.0066 33.5813 33.8871ZM15.7035 17.5594H15.0274C14.6577 17.5594 14.3569 17.2581 14.3569 16.8877V11.4258C14.3569 11.0554 14.6577 10.7541 15.0274 10.7541H15.7035C18.518 10.7541 19.8646 12.2247 19.8646 14.1264C19.8646 16.0281 18.5787 17.5594 15.7035 17.5594Z" fill="white"/>
                            <path d="M141.138 6.84566C137.623 3.4679 132.418 1.39204 125.675 1.39204H112.755C111.828 1.39204 111.078 2.14388 111.078 3.07263V34.6528C111.078 35.5815 111.828 36.3334 112.755 36.3334H125.771C132.372 36.3334 137.623 34.3073 141.235 30.8328C144.366 27.8392 146.196 23.6903 146.196 18.8641C146.196 13.7974 144.22 9.79222 141.138 6.84843M126.698 24.9175H126.105C125.735 24.9175 125.435 24.6162 125.435 24.2458V13.4519C125.435 13.0815 125.735 12.7802 126.105 12.7802H126.698C130.043 12.7802 132.755 15.4974 132.755 18.8475C132.755 22.1976 130.043 24.9148 126.698 24.9148" fill="white"/>
                            <path d="M181.888 34.053L169.871 2.47281C169.623 1.82324 168.999 1.39204 168.304 1.39204H156.886C156.19 1.39204 155.567 1.82324 155.318 2.47281L143.301 34.053C142.882 35.1531 143.693 36.3306 144.869 36.3306H155.44C156.182 36.3306 156.839 35.8414 157.048 35.1255L158.042 31.7449H166.038L166.913 35.0785C167.106 35.8165 167.774 36.3306 168.536 36.3306H180.321C181.496 36.3306 182.307 35.1531 181.888 34.053ZM164.416 24.4974H159.86C159.46 24.4974 159.162 24.1159 159.25 23.7151L161.527 13.2833C161.673 12.6144 162.597 12.6144 162.744 13.2833L165.023 23.7151C165.111 24.1159 164.813 24.4974 164.413 24.4974" fill="white"/>
                            </svg>
                        </div>
                        </div>
                        </div>
                    </HashLink>
                    <FooterItem text={'gastronomía'} link={'/gastronomia#'} size={{width: 'min(32.94vw, 475px)', height: 'min(39.6vw, 570px)'}}/>
                    <div className="item-baseline socials-row" style={{paddingTop: 'initial', paddingLeft: 'initial'}}>
                        <div className="socials-container">
                            <a href="https://www.instagram.com/ronda.ba" target="_blank">
                            <div className="insta-logo logo"><InstaLogo/></div>
                            </a>
                            
                            <a href="https://www.youtube.com/channel/UCD0kGQbO6blonfh8AMSqhcA" target="_blank">
                            <div className="email-logo logo"><EmailLogo/></div>
                            </a>
                        </div>
                    </div>
                </div>
            
                <div className="column-1-wrapper">
                <HashLink style={{textDecoration: 'none', color: 'white'}} smooth to="#top">
                        <div className="item-baseline" style={{paddingBottom: 'min(2vw, 29px)', backgroundColor: hover ? '#000000' : '#1A1A1A', width: 'min(18.5vw, 267px)'}}  onMouseEnter={() => setHover(!hover)} onMouseLeave={() => setHover(!hover)}>
                            <div className="back-to-top-flex item-wrapper" style={{flexDirection: 'row'}}>
                                <div className="back-to-top-text">BACK TO TOP</div>
                                <div className="arrow-wrapper" style={{position: 'relative'}}>
                                    <div style={{transform: 'rotate(270deg)', position: 'absolute', transition: 'bottom ease 0.5s', bottom: hover ? '20%' : '0px'}}>
                                        <svg width="100%" height="100%" viewBox="0 0 41 45" fill="none" xmlns="http://www.w3.org/2000/svg" >
                                        <path d="M19.3 1.86133L39.6464 22.2078C39.8417 22.403 39.8417 22.7196 39.6464 22.9149L19.3 43.2613" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                                        <path d="M38.8 23.2617L0.999985 23.2617" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </HashLink>
                    <div className="row-wrapper" style={{alignItems: 'flex-start'}}>
                        <FooterItem text={'entretenimiento'} link={'/entretenimiento#'} size={{width: 'min(28.6vw, 413px)', height: 'min(25.4vw, 365px)'}}/>
                        <FooterItem text={'eventos'} link={'/eventos#'} size={{width: 'min(20.48vw, 295px)', height: 'min(25.4vw, 365px)'}} center={true}/>
                    </div>
                    <div className="row-wrapper">
                        <FooterItem text={<div>qué es <br/> ronda</div>} link={'/quienes-somos#'} size={{width: 'min(28.6vw, 413px)', height: 'min(16.25vw, 234px)'}}/>
                        
                        <a href="https://www.dotbairesshopping.com/" target="_blank" style={{all: 'initial'}}>
                        <div className="item-baseline item-wrapper" style={{padding: 'initial', alignItems: 'center', justifyContent: 'center', width: 'min(20vw, 289px)', height: 'min(20vw, 289px)', cursor: 'pointer'}}>
                            <img className="dot-logo" src={Dot} alt="dot shopping logo"/>
                        </div>
                        </a>
                    </div>
                </div>
        </div>
        <div className="footer-bottom-text-container">
            <div className="footer-bottom-text">
                Copyright © 2024 | Ronda® . Todos los derechos reservados
            </div>
        </div>
        </div>
        </>
    )
} else {
    return (
        <>
                <div className={`footer-wrapper ${entSite ? 'footer-margin-entertainment' : ''}`} style={style}>
        <div className="footer-boxes-wrapper">
                <div className="column-1-wrapper">
                    <div className="just-self">
                    <HashLink style={{textDecoration: 'none', color: 'white', width: 'fit-content'}} smooth to="#top">
                        <div className="item-baseline added-padding" style={{ backgroundColor: hover ? '#000000' : '#1A1A1A', width: 'min(60.19vw, 248px)'}}  onMouseEnter={() => setHover(!hover)} onMouseLeave={() => setHover(!hover)}>
                            <div className="back-to-top-flex " style={{flexDirection: 'row'}}>
                                <div className="back-to-top-text">BACK TO TOP</div>
                                <div className="arrow-wrapper" style={{position: 'relative'}}>
                                    
                                        <svg width="100%" height="100%" viewBox="0 0 31 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.5 14.921L15.2609 1.16014C15.3929 1.02808 15.6071 1.02808 15.7391 1.16014L29.5 14.921" stroke="white" strokeWidth="1.35266" strokeLinecap="round"/>
                                        <path d="M15.7031 1.73267L15.7031 27.2979" stroke="white" strokeWidth="1.35266" strokeLinecap="round"/>
                                        </svg>

                                    
                                </div>
                            </div>
                        </div>
                    </HashLink>
                    </div>
                    <HashLink to={'/home#'}>
                        <div className="item-baseline added-padding" >
                        <div className="item-wrapper-centered">
                        <div style={{width: 'min(32.45vw, 133.73px)', height: 'min(6.41vw, 26.45px)'}}>
                            <svg width="100%" height="100%" viewBox="0 0 134 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M37.7758 0.182617C45.8105 0.182617 52.0754 5.96273 52.0754 13.4082C52.0754 20.8537 45.8105 26.6338 37.7758 26.6338C29.741 26.6338 23.4762 20.8537 23.4762 13.4082C23.4762 5.96273 29.741 0.182617 37.7758 0.182617ZM37.5203 17.5494C39.9167 17.6996 41.8813 15.7316 41.7333 13.331C41.6117 11.359 39.9999 9.74641 38.0312 9.62252C35.6348 9.47426 33.6702 11.4402 33.8202 13.8408C33.9419 15.8129 35.5537 17.4255 37.5223 17.5494" fill="white"/>
                            <path d="M54.3806 0.572561H62.093C62.4985 0.572561 62.8776 0.771595 63.1067 1.1067L70.2798 11.5296L70.3508 11.4951V1.80738C70.3508 1.12498 70.9023 0.572561 71.5835 0.572561H78.6066C79.2878 0.572561 79.8392 1.12498 79.8392 1.80738V25.0111C79.8392 25.6935 79.2878 26.2459 78.6066 26.2459H70.8921C70.4866 26.2459 70.1075 26.0469 69.8784 25.7118L62.7053 15.2889L62.6343 15.3234V25.0111C62.6343 25.6935 62.0829 26.2459 61.4016 26.2459H54.3785C53.6973 26.2459 53.1459 25.6935 53.1459 25.0111V1.80738C53.1459 1.12498 53.6973 0.572561 54.3785 0.572561" fill="white"/>
                            <path d="M24.8386 24.4485L19.918 14.8279C21.8299 13.4813 23.1396 11.4951 23.1396 8.7289C23.1376 1.88456 15.9523 0.572561 10.6769 0.572561H1.39724C0.716017 0.572561 0.164551 1.12498 0.164551 1.80738V25.0111C0.164551 25.6935 0.716017 26.2459 1.39724 26.2459H9.48068C10.1619 26.2459 10.7134 25.6935 10.7134 25.0111V17.8053H10.8553L14.3506 25.5209C14.5513 25.9616 14.9893 26.2459 15.4718 26.2459H23.7418C24.6622 26.2459 25.2583 25.2711 24.8386 24.4485ZM11.7028 12.4516H11.206C10.9344 12.4516 10.7134 12.2303 10.7134 11.9581V7.94495C10.7134 7.6728 10.9344 7.45142 11.206 7.45142H11.7028C13.7708 7.45142 14.7602 8.53189 14.7602 9.92919C14.7602 11.3265 13.8154 12.4516 11.7028 12.4516Z" fill="white"/>
                            <path d="M103.867 4.57964C101.284 2.09781 97.4598 0.572561 92.5047 0.572561H83.0122C82.331 0.572561 81.7795 1.12498 81.7795 1.80738V25.0111C81.7795 25.6935 82.331 26.2459 83.0122 26.2459H92.5757C97.4253 26.2459 101.284 24.7572 103.938 22.2043C106.239 20.0048 107.583 16.9563 107.583 13.4103C107.583 9.68751 106.131 6.74465 103.867 4.58168M93.2569 17.8581H92.821C92.5493 17.8581 92.3283 17.6367 92.3283 17.3645V9.43364C92.3283 9.16149 92.5493 8.94011 92.821 8.94011H93.2569C95.7142 8.94011 97.7072 10.9365 97.7072 13.3981C97.7072 15.8596 95.7142 17.856 93.2569 17.856" fill="white"/>
                            <path d="M133.808 24.5704L124.978 1.36667C124.796 0.889391 124.338 0.572561 123.827 0.572561H115.437C114.926 0.572561 114.468 0.889391 114.286 1.36667L105.456 24.5704C105.148 25.3787 105.744 26.2439 106.608 26.2439H114.375C114.92 26.2439 115.403 25.8844 115.557 25.3584L116.287 22.8745H122.162L122.805 25.3239C122.947 25.8661 123.438 26.2439 123.997 26.2439H132.656C133.52 26.2439 134.116 25.3787 133.808 24.5704ZM120.97 17.5494H117.623C117.329 17.5494 117.11 17.2691 117.175 16.9746L118.847 9.30975C118.955 8.81826 119.634 8.81826 119.741 9.30975L121.416 16.9746C121.481 17.2691 121.262 17.5494 120.968 17.5494" fill="white"/>
                            </svg>

                        </div>
                        </div>
                        </div>
                    </HashLink>
                    <FooterItem text={'gastronomía'} link={'/gastronomia#'} size={{width: '100%', height: 'min(69.66vw, 287px)'}}/>
                </div>
                <FooterItem text={'entretenimiento'} link={'/entretenimiento#'} size={{width: '100%', height: 'min(39.56vw, 163px)'}}/>
                
                    <div className="flex-row" >
                        <FooterItem text={<div>qué es <br/> ronda</div>} link={'/quienes-somos#'} size={{width: 'min(44.98vw, 185px)', height: 'min(35.67vw, 147px)'}}/>
                        <FooterItem text={'eventos'} link={'/eventos#'} size={{width: 'min(50.48vw, 208px)', height: 'min(35.67vw, 147px)'}} center={true}/>
                    </div>
                    
                
                <div className="item-baseline-centered socials-row" style={{paddingTop: 'initial', paddingLeft: 'initial'}}>
                        <div className="socials-container">
                            <a href="https://www.instagram.com/ronda.ba" target="_blank">
                            <div className="insta-logo logo"><InstaLogo/></div>
                            </a>
                            <a href="https://www.youtube.com/channel/UCD0kGQbO6blonfh8AMSqhcA" target="_blank">
                            <div className="email-logo logo"><EmailLogo/></div>
                            </a>
                        </div>
                    </div>
        </div>
        <div className="footer-bottom-text-container">
            <div className="footer-bottom-text">
                Copyright © 2024 | Ronda® . <br/> Todos los derechos reservados
            </div>
        </div>
        </div>
        </>
    )
}
}

export default Footer;