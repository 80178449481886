import React from "react";
import './components-css/navbar.css';
import { HashLink } from "react-router-hash-link";
import {ReactComponent as NavLogo} from '../assets/imgs/nav/nav-logo.svg';

function NavBar ({dark}) {
    return (
        <>
        
        <div className="nav-wrapper" style={dark ? {color: 'black'} : {}}>

            <div className="absolute-wrapper" style={{display: 'none'}}>
                    <div className="nav-mid-logo">
                    <HashLink to={'/home'}>
                        <NavLogo/>
                    </HashLink>
                    </div>
                    
                </div>
                <div style={{/*paddingLeft: 'min(9vw, 131px)'*/}}>
                    <div className="nav-module module-left hide-mobile">
                        <HashLink to={'/gastronomia'} style={{all: 'inherit'}}>
                            <div className="nav-item">
                            Gastronomia
                            </div>
                        </HashLink>
                        <HashLink to={'/entretenimiento'} style={{all: 'inherit'}}>
                            <div className="nav-item">
                            Entretenimiento
                            </div>
                        </HashLink>

                    </div>
                </div>
                <div className="nav-mid-logo" >
                <HashLink to={'/home'} style={{all: 'inherit', cursor: 'pointer'}}>
                    <NavLogo/>
                </HashLink>
                </div>
                <div style={{/*paddingRight: 'min(9vw, 131px)'*/}}>
                <div className="nav-module module-right hide-mobile">
                    <HashLink to={'/quienes-somos'} style={{all: 'inherit'}}>
                        <div className="nav-item">
                        Quienes somos
                        </div>
                    </HashLink>
                    <HashLink to={'/eventos'} style={{all: 'inherit'}}>
                        <div className="nav-item">
                        Eventos
                        </div>
                    </HashLink>
                </div>
            </div>
        </div>
       
        </>
    )
}

export default NavBar;