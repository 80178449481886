import React from "react";


import Banner from '../../../Components/Banner';
import { EntSiteCover } from '../../../Components/entretenimiento/EntSiteCover';
import NavBar from '../../../Components/NavBar';

import {ReactComponent as ObrasBrevesLogo} from '../../../assets/imgs/entretenimiento/obras-breves-logo.svg';

import VerButton from '../../../Components/VerButton';
import DarkModeWrapper from '../../../Components/DarkModeWrapper';
import Footer from '../../../Components/Footer';
import { OtherEnts } from '../../../Components/entretenimiento/OtherEnts';
import ExpoActuales from "../../../Components/ExpoActuales";

import { MobileMenu } from "../../../Components/MobileMenu";
import InstagramRow from "../../../Components/InstagramRow";

import MS1 from '../../../assets/imgs/obras-breves/mosaic-1.webp';
import MS2 from '../../../assets/imgs/obras-breves/mosaic-2.webp';
import MS3 from '../../../assets/imgs/obras-breves/mosaic-3.webp';
import MS4 from '../../../assets/imgs/obras-breves/mosaic-4.webp';

function ObrasBreves() {
    return (
        <>
        <MobileMenu/>
        <div className='centering-wrapper' style={{backgroundColor: 'rgb(232, 78, 15)'}}>
            <div className='max-width-container'>
                
                <NavBar/>
                <EntSiteCover schedule={'Jueves a Domingos'}>
                    <div className='obras-breves-logo'>
                        <ObrasBrevesLogo/>
                    </div>
                </EntSiteCover>
                <div>
                
                </div>
            </div>
        </div>
        <div className='centering-wrapper'>
            <div className='max-width-container'>
                <div className='ent-site-body-wrapper'>
                    <div className='ent-site-header-wrapper'>
                        <div className='ent-site-title'>
                            Obras breves, 
                            un sin fin de emociones. <br/> 
                            ¡Que empiece el show!
                        </div>
                        <div className='ent-site-description'>
                            Una experiencia artística con un sin fin de emociones. 3 salas, obras de 20 minutos y 30 espectadores.
                        </div>
                        <VerButton link={'https://obrasbrevesronda.alternativa.ar/'} newtab={true}>Comprar entradas</VerButton>
                       
                    </div>



                    {/*make it a component?*/}
                    <div className="ent-site-card-grid-wrapper-column "
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        paddingTop: '58px'
                    }}>
                        <div className="insta-mobile-margin">
                        <InstagramRow dark={false} margin={false} link={'https://www.instagram.com/obrasbreves.ronda?igsh=MWVvdHppMWxjdXV5Yw=='}/>
                        </div>
                        <div className='ent-site-card-grid-wrapper' style={{paddingTop: 0}}>
                            
                            <div className='ent-col-2'>
                                <div className='ent-col-2-row-1 square-radius'>
                                    <img className='mosaic-image' src={MS2} alt=""/>
                                </div>
                                <a href='https://obrasbrevesronda.alternativa.ar/' target='_blank' rel='noreferrer'
                                style={{textDecoration: 'none'}} className="comprar-entradas-hover">
                                <div className='ent-col-2-row-2 square-radius' style={{backgroundColor: 'rgb(232, 78, 15)', display: 'flex', alignItems: 'center', position: 'relative'}}>
                                    <div style={{
                                        width: '100%',
                                        height: '100%',
                                        position: 'absolute',
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        alignItems: 'flex-start',
                                        paddingTop: '15px',
                                        paddingRight: '15px',
                                        boxSizing: 'border-box'
                                    }}>
                                    <svg width="24" height="24" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6.62187 4.21215C6.41523 4.41883 6.41525 4.75389 6.62193 4.96053C6.82861 5.16718 7.16367 5.16715 7.37032 4.96048L6.62187 4.21215ZM7.37032 4.96048L10.6192 1.71108L9.87076 0.962751L6.62187 4.21215L7.37032 4.96048Z" fill="white"/>
                                    <path d="M7.90818 1.18166H10.1044C10.1967 1.18166 10.2715 1.25648 10.2715 1.34877V3.54498" stroke="white" strokeWidth="1.05838" strokeLinecap="round"/>
                                    <path d="M4.52792 1.05835H1.35279C1.15795 1.05835 1 1.2163 1 1.41114V10.2309C1 10.4258 1.15795 10.5837 1.35279 10.5837H10.1726C10.3674 10.5837 10.5254 10.4258 10.5254 10.2309V7.05581" stroke="white" strokeWidth="1.05838" strokeLinecap="round"/>
                                    </svg>

                                    </div>
                                    <div className='ent-col-2-row-2-content' style={{paddingTop: 0}}>
                                        <div className='ent-col-1-title'>
                                            COMPRAR ENTRADAS
                                        </div>
                                        
                                        
                                        
                                    </div>
                                </div>
                                </a>
                            </div>
                            <div className='ent-col-3'>
                                <div className='ent-col-3-row-1'>
                                    <div className='ent-col-3-row-1-col-1 square-radius'>
                                        <img className='mosaic-image' src={MS3} alt=""/>
                                    </div>
                                    <div className='ent-col-3-row-1-col-2 square-radius'>
                                        <img className='mosaic-image' src={MS1} alt=""/>
                                    </div>
                                </div>
                                <div className='ent-col-3-row-2 square-radius'>
                                <img className='mosaic-image' src={MS4} alt=""/>
                                </div>
                            </div>
                        </div>
                        </div>
                    {/*/component*/}
                    
                    <div className='call-to-events-wrapper' style={{display: 'none'}}>
                        <div className='call-to-events-title'>
                            QUERÉS FESTEJAR TU CUMPLE JUGANDO? CONTACTANOS!
                        </div>
                        <VerButton link={'/eventos'}>Ver todo</VerButton>
                    </div>


                </div>
            </div>
        </div>
        <div className='centering-wrapper' style={{backgroundColor: '#F0F0F0', marginBottom: '-20px'}}>
            <div className='max-width-container'>
                <OtherEnts inObras={true}/>
            </div>
        </div>
        <DarkModeWrapper>
            <Footer entSite={true}/>
        </DarkModeWrapper>
        </>
    )
}

export default ObrasBreves;