import React from "react";
import { useState, useEffect, useRef } from "react";
import './components-css/shop.css';

import VerButton from '../Components/VerButton';

import {ReactComponent as VeganIcon} from '../assets/imgs/shops/icons/vegan_lg.svg';
import {ReactComponent as CeliacIcon} from '../assets/imgs/shops/icons/celiac_lg.svg';

function ShopPopUp({data, show, closeCallback, page, defaultImage}) {

    const divRef = useRef(null);

    const [defImage] = useState(defaultImage);
    
    //page === null -> gastronomia

    const handleShow = (open) => {
        if (divRef.current != null) {
            if(open) {
                divRef.current.style.display = 'flex';          
                setTimeout(() => {
                    divRef.current.style.opacity = 1;
                }, 20);
            } else {
                divRef.current.style.opacity = 0;
                setTimeout(() => {
                    divRef.current.style.display = 'none';
                    closeCallback();
                }, 300);
            }
        }
    }

   useEffect(() => {
    handleShow(show);
   }, [])

   const determineBgImage = () => {
    if ((data.imagenPopUp == null || data.imagenPopUp == "") && defImage == null) {
        return "";
    } else {
        if (data.imagenPopUp == null || data.imagenPopUp == "") {
            return defImage;
        }
    }
    return data.imagenPopUp;
   }

    return (
        <>
        <div ref={divRef} className="over-background-wrapper" >
            <div className="pop-up-wrapper" >
                <div className="close-pop-up-row">
                    <div className="close-pop-up-button-row" onClick={() => handleShow(false)}>
                        <div className="close-text">
                            Cerrar
                        </div>
                        <div className="close-icon-wrapper">
                            <svg width="100%" height="100%" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M53.2073 18.207L18.2074 53.2069" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                            <path d="M51.7932 53.207L16.7933 18.2071" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                            </svg>
                        </div>

                    </div>
                </div>

                <div className="pop-up-content-wrapper" > 
                    <div className="pop-up-content-left-column">
                        {/*<img src={data!=null ? data.bgImage : "#"} className="pop-up-image"></img>*/}
                        {<img src={determineBgImage()} className="pop-up-image" alt="cover de popup del local"/>}
                    </div>
                    <div className="pop-up-content-right-column" >
                        <div className="right-column-content-container" style={page != null ? {justifyContent: 'flex-start', paddingTop: 'min(5.34vw, 77px)'} : {}}>
                            <div className="title-pills-gap" style={{display: 'flex', flexDirection: 'column'}}>
                                <div className="pill-container">
                                    {page == null ? 
                                    <>
                                    <div className="popup-pill-smaller" style={!data.inPlace ? {display: 'none'} : {}}>
                                        In Place
                                    </div>
                                    <div className="popup-pill-smaller" style={!data.takeAway ? {display: 'none'} : {}}>
                                        Take Away
                                    </div>
                                    </>
                                    : 
                                    null
                                    }
                                </div>
                                <div className="pop-up-title">
                                    {data.title}
                                </div>

                            </div>
                            <div className="title-pills-gap" style={{display: 'flex', flexDirection: 'column'}}>
                                <div className="description-wrapper">
                                    <div className="pop-up-description">
                                        {data.description}
                                    </div>
                                </div>
                                {page == null ?
                                <div style={{display: 'flex', alignItems: 'center', gap: 'min(0.55vw, 8px)'}}>
                                    <div className="food-icon food-icon-on-popup" style={!data.vegan ? {display: 'none'} : {}}>
                                        <VeganIcon/>
                                    </div>
                                    <div className="food-icon food-icon-on-popup" style={!data.celiac ? {display: 'none'} : {}}>
                                        <CeliacIcon/>
                                    </div>
                                </div>
                                : null}
                            </div>
                            {page == null ?
                            <VerButton text="Ver menú" link={data.menuLink != null ? data.menuLink : "#"} light={true} newtab={true} smaller={true}/>
                            : null}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default ShopPopUp;