import React, { useEffect } from "react";
import { useState, useRef } from "react";

function FaqRow({rowData, informClick, current, index}) {

  const openDescription = useRef(false);
  const [open, setOpen] = useState(false);
  const [rowId, setRowId] = useState(index);
  const [currentRow, setCurrentRow] = useState(current);
 

  useEffect(() => {
    setCurrentRow(current);
    setRowId(index);
  }, [])

  const handleOpen = () => {
    openDescription.current = !openDescription.current;
    setOpen(openDescription.current);
    informClick(rowId);
  }

  useEffect(() => {
    if(current === rowId) {
      openDescription.current = true;
      setOpen(true);
    } else {
      openDescription.current = false;
      setOpen(false);
    }
  }, [current])

 

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsVisible(true);
    }, 100);

    
    return () => clearTimeout(timeoutId);
  }, []); 

  return (
    <>
    <div className="faq-row-container" style={isVisible ? {opacity: 1} : {opacity: 0}}>
        <div className="faq-row-header-container" onClick={() => {handleOpen()}}>
          <div>{rowData.title}</div>
          <div style={{width: 'min(1.31vw, 19px', height: 'auto'}}>
            <svg width="100%" height="100%" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.5 1.88477L9.5 17.6943" stroke="white" strokeWidth="2" strokeLinecap="round"/>
            <path d="M17.5 9.88477L1.69043 9.88476" stroke="white" strokeWidth="2" strokeLinecap="round"/>
            </svg>
          </div>
        </div>
        <div className="faq-row-description-container" style={!open ? {maxHeight: '0px', opacity: 0} : {maxHeight: '3000px', opacity: 1}}>
          <div className="faq-row-description">
            {rowData.description}
          </div>
        </div>

    </div>
    </>
  )
}

export default FaqRow;