import React from "react";
import { HashLink } from "react-router-hash-link";
import './components-css/footer.css';
import { useState } from "react";

function FooterItem({text, link, size, center}) {
    const [hover, setHover] = useState(false);

    const styleCenter = center ? {display: 'flex', alignItems: 'center'} : {};

    const combinedStyles = {...size, ...styleCenter};

    return (
        <>
        <HashLink to={link} style={{textDecoration: 'none'}}>
            <div className="item-baseline" style={combinedStyles} onMouseEnter={() => {setHover(!hover)}} onMouseLeave={() => {setHover(!hover)}}>
                <div className="item-wrapper" style={center ? {paddingTop: 'initial'} : {}}>
                <div className="footer-item-text">
                    {text}
                </div>
                <div className="arrow-wrapper horizontal-arrow-wrapper" style={hover ? {paddingLeft: '10%'} : {paddingLeft: '0px'}}>
                    <svg width="100%" height="100%" viewBox="0 0 41 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M19.3 1.86133L39.6464 22.2078C39.8417 22.403 39.8417 22.7196 39.6464 22.9149L19.3 43.2613" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                    <path d="M38.8 23.2617L0.999985 23.2617" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                    </svg>
                </div>
                </div>
            </div>
        </HashLink>
        </>
    )
}

export default FooterItem;