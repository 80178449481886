import React from "react";

import '../components-css/explora-tambien.css';
import '../components-css/saborea-card.css';

import VerButton from "../VerButton";

import Cover from '../../assets/imgs/cards/observa-cover.webp';

//DEPRECATED
function ObservaCard() {
    return (
        <>
            <div className="explora-cards-container" style={window.innerWidth > 478 ? {flexDirection: 'row-reverse'} : {}}>
                <div className="explora-left-card-wrapper" style={{backgroundColor: '#E84E0F'}}>
                    <div className="observa-right-card-content" >
                        <div className="observa-card-img-row">
                            <img className="observa-card-img" src={Cover} alt="observa-card-img"/>
                        </div>
                        
                    </div>
                </div>
                <div className="explora-right-card" style={{backgroundColor: '#BA3E0C'}}>
                    <div className="explora-right-container">
                        <div className="right-header-container">
                            <div className="right-header-text">ARTE</div>
                            <div className="right-header-title">OBSERVÁ</div>
                        </div>
                        <div className="right-bottom-container">
                            <div className="bottom-right-text">
                                El arte es una diagonal que atraviesa nuestra totalidad.
                            </div>
                            <VerButton text={"Ver más"} link={'/arte#'} light={false}/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ObservaCard;